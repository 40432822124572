import Api from '@/services/Index';

const getAll = async (filters) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/courses/', filters);
}

const getEntry = async (courseId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/courses/entry/' + courseId, formData);
}

const getActiveCourses = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/courses/active-courses', config);
}

const updateEntry = async (courseId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/student/courses/entry/' + courseId, formData);
}

const sectionUpdateList = async (filters) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/courses/section/updates/', filters);
}

const sectionUpdate = async (update) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/student/courses/section/updates', update);
}

const get = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/courses/' + id, formData);
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/courses', formData);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/student/courses/' + id, formData);
}

const move = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/student/courses/' + id + '/move', formData);
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/student/courses/' + id);
}

const downloadAsExcel = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/courses/excel-export', {
        ...config,
        //responseType:'arraybuffer'
    });
}

const finalizeGradesJob = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/courses/finalize-grades', formData);
}

const convertGradeTo_I = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/courses/convert-i-grades', formData);
}

const instructorLetterGradeExcelReport = (section_id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/courses/instructor-letter-grade-excel-export/' + section_id, { responseType: 'arraybuffer' });
}

const getStudentAffairsEntry = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/courses/student-affairs-entry', config);
}

const updateStudentAffairsEntry = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/student/courses/student-affairs-entry', formData);
}

const importLetterGradeForStudentAffairs = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/courses/import-letter-grade-for-student-affairs', formData, { responseType: 'arraybuffer' });
}

export default {
    getAll,
    getEntry,
    getActiveCourses,
    updateEntry,
    sectionUpdateList,
    sectionUpdate,
    get,
    store,
    update,
    move,
    del,
    downloadAsExcel,
    finalizeGradesJob,
    convertGradeTo_I,
    instructorLetterGradeExcelReport,
    getStudentAffairsEntry,
    updateStudentAffairsEntry,
    importLetterGradeForStudentAffairs
}
